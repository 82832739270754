import { ServicesMock } from '../../data/ServicesData/ServicesData';
import './Services.scss';

const Services = () => {
  return (
    <div className="services">
      <div className="text-container">
        <div className="title-container">
          <p className="title">Services</p>
        </div>
        <div className="text-button-container">
          <p className="text">
            Normasoft is providing all necessary services needed for the development and maintenance
            of small to mid-size IT software projects:
          </p>
        </div>
      </div>
      <div className="icon-container">
        {ServicesMock.map((el: any, i: number) => {
          return (
            <div className="box" key={el.id}>
              <div className="img-circle">
                <img className="img" src={el.gif} />
              </div>
              <p className="text">{el.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
