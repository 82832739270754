import {
  TechnologiesMock,
  technologiesMockData
} from '../../data/TechnologiesData/TechnologiesData';
import './Technologies.scss';
import Keyboard from '../../assets/keyboard.gif';

const Technologies = () => {
  return (
    <div className="technologies">
      <div className="inner-container">
        <div className="container-left">
          <p className="title">Techonologies</p>
          <div className="text-container">
            <p className="text">We prefer, but we are not limited to using the following:</p>
            {TechnologiesMock.map((el: any, i: number) => {
              return (
                <p className="text" key={i}>
                  <span className="dot">•</span>
                  {el.text}
                </p>
              );
            })}
            <p className="text">Normasoft also has significant experience in the following:</p>
            {technologiesMockData.map((el: any, i: number) => {
              return (
                <p className="text" key={i}>
                  <span className="dot">•</span>
                  {el.text}
                </p>
              );
            })}
          </div>
        </div>
        <div className="container-right">
          <div className="container-right-img"></div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
