import { useRef, useState } from 'react';
import './ContactForm.scss';
import emailjs from '@emailjs/browser';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';
import { emailReg } from '../../Regex/Regex';

const ContactForm = () => {
  const form = useRef<any>();
  const toast = useRef<any>(null);
  const [emailValidation, setEmailValidation] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true
  });

  const sendEmail = (e: any) => {
    console.log(form.current);
    emailjs.sendForm('service_9d1iwff', 'template_co34s5a', form.current, 'HY82_sAfDI-k08XRa').then(
      (result) => {
        form.current.reset();
        toast.current.show({
          severity: 'success',
          detail: 'Uspešno ste poslali poruku!',
          life: 3000
        });
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const isValidEmail = (email: string) => emailReg.test(email);

  const handleEmailValidation = (email: string) => {
    setEmailValidation(0);
    const isValid = isValidEmail(email);

    return isValid;
  };

  return (
    <div className="contact-form">
      <Toast ref={toast} position={'top-right'} />
      <div className="container">
        <div className="contact-form-header">
          <p className="title">Contact us</p>
        </div>
        <div className="triangle"></div>
        <form className="form" ref={form} onSubmit={handleSubmit(sendEmail)}>
          <div className="row">
            <div className="input-field">
              <label className="label">
                Name<span className="required">*</span>
              </label>
              <input
                className={errors.user_name ? 'form-box-error' : 'form-box'}
                {...register('user_name', {
                  required: true
                })}
                type="text"
                placeholder={'Name'}
              />
            </div>
            <div className="input-field">
              <label className="label">
                Surname Name<span className="required">*</span>
              </label>
              <input
                className={errors.user_name ? 'form-box-error' : 'form-box'}
                {...register('user_surrname', {
                  required: true
                })}
                type="text"
                placeholder={'Surname'}
              />
            </div>
          </div>
          <div className="row">
            <div className="input-field">
              <label className="label">
                Email Name<span className="required">*</span>
              </label>
              <input
                type="email"
                className={
                  emailValidation === 401 || errors.user_email ? 'form-box-error' : 'form-box'
                }
                {...register('user_email', {
                  required: true,
                  validate: handleEmailValidation
                })}
                placeholder={'Email'}
              />
              {emailValidation === 401 && <p className="message">Your email already exists</p>}
            </div>
            <div className="input-field">
              <label className="label">
                Phone Name<span className="required">*</span>
              </label>
              <input
                className={errors.user_name ? 'form-box-error' : 'form-box'}
                {...register('user_phone', {
                  required: true
                })}
                type="text"
                placeholder={'Phone'}
              />
            </div>
          </div>
          <div className="second-row">
            <div className="input-field">
              <label className="label">Message</label>
              <textarea className="textarea" name="message" placeholder="Message" />
            </div>
          </div>
          <div className="last-row">
            <div className="checbox-container"></div>
            <input className="btn" type="submit" value="Send" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
