import './Header.scss';
import Navigation from '../Navigation/Navigation';
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

const Header = (props: any) => {
  return (
    <div className="header">
      <div className={'inner-container'}>
        <div className="navigation">
          <div className="image-container">
            <img className="image" src={Logo} />
          </div>
          <Navigation
            scrollToServices={props.scrollToServices}
            scrollToTechnologies={props.scrollToTechnologies}
            scrollToProducts={props.scrollToProducts}
            scrollTocompanyReferences={props.scrollTocompanyReferences}
            scrollToContactForm={props.scrollToContactForm}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
