import './Navigation.scss';
import { Link, useLocation } from 'react-router-dom';

const Navigation = (props: any) => {
  const location = useLocation();

  const NavigationData: any = [
    { name: 'Services', scroll: props.scrollToServices, link: '#services' },
    { name: 'Technologies', scroll: props.scrollToTechnologies, link: '#technologies' },
    { name: 'Products', scroll: props.scrollToProducts, link: '#products' },
    { name: 'References', scroll: props.scrollTocompanyReferences, link: '#references' },
    { name: 'Contact', scroll: props.scrollToContactForm, link: '#contact' }
  ];

  return (
    <div className="navigation-inner-container">
      {NavigationData.map((el: any, i: number) => {
        return (
          <Link
            to={el.link}
            onClick={() => el.scroll()}
            key={i + 1}
            className={`nav-item   ${el.link === location.hash && 'activeClass'}
          `}>
            {el.name}
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
