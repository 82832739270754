import { useRef, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import CompanyReferences from '../../components/CompanyReferences/CompanyReferences';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Products from '../../components/Products/Products';
import Services from '../../components/Services/Services';
import Technologies from '../../components/Technologies/Technologies';
import './LandingPage.scss';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';

const LandingPage = () => {
  const servicesRef = useRef<any>();
  const technologiesRef = useRef<any>();
  const productsRef = useRef<any>();
  const companyReferences = useRef<any>();
  const contactFormRef = useRef<any>();

  const scrollToServices = () => {
    window.scrollTo({
      top: servicesRef.current.offsetTop - 70,
      behavior: 'smooth'
    });
  };
  const scrollToTechnologies = () => {
    window.scrollTo({
      top: technologiesRef.current.offsetTop - 70,
      behavior: 'smooth'
    });
  };
  const scrollToProducts = () => {
    window.scrollTo({
      top: productsRef.current.offsetTop - 70,
      behavior: 'smooth'
    });
  };
  const scrollTocompanyReferences = () => {
    window.scrollTo({
      top: companyReferences.current.offsetTop - 70,
      behavior: 'smooth'
    });
  };
  const scrollToContactForm = () => {
    window.scrollTo({
      top: contactFormRef.current.offsetTop - 70,
      behavior: 'smooth'
    });
  };

  return (
    <div className="landing-page">
      <Header
        scrollToServices={scrollToServices}
        scrollToTechnologies={scrollToTechnologies}
        scrollToProducts={scrollToProducts}
        scrollTocompanyReferences={scrollTocompanyReferences}
        scrollToContactForm={scrollToContactForm}
      />
      <MobileHeaderSmall
        scrollToServices={scrollToServices}
        scrollToTechnologies={scrollToTechnologies}
        scrollToProducts={scrollToProducts}
        scrollTocompanyReferences={scrollTocompanyReferences}
        scrollToContactForm={scrollToContactForm}
      />
      <Banner scrollToContactForm={scrollToContactForm} />
      <div ref={servicesRef}>
        <Services />
      </div>
      <div ref={technologiesRef}>
        <Technologies />
      </div>
      <div ref={productsRef}>
        <Products />
      </div>
      <div ref={companyReferences}>
        <CompanyReferences />
      </div>
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
