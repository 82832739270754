import './Banner.scss';
import bannerImage from '../../assets/banner-image.png';

const Banner = (props: any) => {
  return (
    <div className="banner">
      <div className="inner-container">
        <div className="left-container">
          <p className="title">
            Normasoft is an experienced <span className="bold">software development</span> company
            that successfully conduct business for over <span className="bold">ten years</span>.
          </p>
          <p className="subtitle">
            The team is a balanced mixture of seniors with over 20 years of relevant IT experience
            and young lions eager to learn, solve issues, engage in new challenges, and prove
            themselves.
          </p>
          <div className="btn-container">
            <button onClick={props.scrollToContactForm} className="btn">
              CONTACT
            </button>
            <a style={{ textDecoration: 'none' }} href="tel:+381 69 2621001">
              <button className="btn-mobile">CONTACT</button>
            </a>
          </div>
        </div>
        <div className="right-container">
          <div
            className="img"
            style={{
              backgroundImage: `url('${bannerImage}')`
            }}></div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
