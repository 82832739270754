import React, { useState } from 'react';
import './MobileHeaderSmall.scss';
import Logo from '../../assets/logo.png';
import { Squash as Hamburger } from 'hamburger-react';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';

const MobileHeaderSmall = (props: any) => {
  const [isOpen, setOpen] = useState(false);

  const handleSidebarOpen = () => {
    return setOpen(false);
  };

  return (
    <div className={'mobile-header-small border-mobile '}>
      {isOpen && (
        <Sidebar
          scrollToServices={props.scrollToServices}
          scrollToTechnologies={props.scrollToTechnologies}
          scrollToProducts={props.scrollToProducts}
          scrollTocompanyReferences={props.scrollTocompanyReferences}
          scrollToContactForm={props.scrollToContactForm}
          handleSidebarOpen={handleSidebarOpen}
          isOpen={isOpen}
        />
      )}
      <div className="inner-container">
        <div className="navigation">
          <img className="image" src={Logo} />
          <div className="burger-menu">
            {<Hamburger toggled={isOpen} toggle={setOpen} color={'#0a2d5c'} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeaderSmall;
