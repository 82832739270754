import './Footer.scss';
import logo from '../../assets/normasoft-icon.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="inner-container">
        <div className="first-container">
          <img className="img" src={logo} />
        </div>
        <div className="second-container">
          <p className="title">Contact:</p>
          <p className="white">Addresses: Zmaj Jovina 7, Belgrade, Obilicev venac 19, Belgrade</p>
          <p className="white">Email: office@normasoft.net</p>
          <p className="white">Phones: +381 69 2621001, +381 60 0961976, +381 69 774454</p>
        </div>
        <div className="third-container">
          <p className="title">Social</p>
          <div className="social-network">
            <a href="https://www.linkedin.com/company/normasoft/">
              <p className="white">Linkedin</p>
            </a>
            <a href="https://maps.app.goo.gl/p5KpudnDJiwbdgjPA">
              <p className="white">Google</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
