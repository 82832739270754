import Icon1 from '../../assets/icon1.gif';
import Icon2 from '../../assets/icon2.gif';
import Icon3 from '../../assets/icon3.gif';
import Icon4 from '../../assets/icon4.gif';
import Icon5 from '../../assets/icon5.gif';
import Icon6 from '../../assets/icon6.gif';
import Icon7 from '../../assets/icon7.gif';
import Icon8 from '../../assets/icon8.gif';

export const ServicesMock = [
  { id: 1, text: 'Business analysis', gif: Icon1 },
  { id: 2, text: 'Project management', gif: Icon2 },
  { id: 3, text: 'UI/UX design and prototyping', gif: Icon3 },
  { id: 4, text: 'System architecture design', gif: Icon4 },
  { id: 5, text: 'General purpose programming', gif: Icon5 },
  { id: 6, text: 'Mobile applications development', gif: Icon6 },
  { id: 7, text: 'Testing', gif: Icon7 },
  { id: 8, text: 'DevOps', gif: Icon8 }
];
