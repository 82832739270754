export const TechnologiesMock = [
  { id: 1, text: 'NodeJs/Express/TypeScript/PostgreSQL - server-side applications, APIs, etc.' },
  { id: 2, text: 'C#/Entity/MSSQL framework - server-side applications, APIs, etc.' },
  { id: 3, text: 'TypeScript/React - client web applications' },
  { id: 4, text: 'TypeScript/ReactNative - mobile applications' },
  { id: 5, text: 'Linux servers, Docker, CI/CD - DevOps' },
  { id: 6, text: 'NodeJs and Go microservices - for resource-sensitive concurrent systems' },
  { id: 7, text: 'Figma - UI/UX prototyping' }
];

export const technologiesMockData = [
  { id: 1, text: 'Swift - IOs mobile development' },
  { id: 2, text: 'Kotlin - Android mobile development' },
  { id: 3, text: 'Mongo DB' },
  { id: 4, text: 'ML - Neural network training' }
];
