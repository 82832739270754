import { ProductsMockData } from '../../data/ProductsData/ProductsData';
import './Products.scss';
import checkIcon from '../../assets/check.png';
import ProductImage from '../../assets/productImage.png';
import ProductImage1 from '../../assets/productImage1.png';
import { Image } from 'antd';

const Products = () => {
  return (
    <div className="products">
      <div className="inner-container">
        <p className="title">Products</p>
        <div className="first-inner-container">
          <div className="first-inner-left">
            <p className="first-inner-left-title">Evidues</p>
            <p className="first-inner-left-text">
              With the experience acquired during the years, Normasoft has developed a lite and
              flexible <b>Document Management System</b>, <span className="green">Evideus</span>,
              focused on supporting the needs of small to medium size companies. Among others, it
              covers:
            </p>
            {ProductsMockData.map((el: any, i: number) => {
              return (
                <div key={i} className="row">
                  <img className="img" src={checkIcon} />
                  <p className="row-text">{el.text}</p>
                </div>
              );
            })}
          </div>
          <div className="first-inner-right">
            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`)
              }}>
              <Image className="img" src={ProductImage} />
            </Image.PreviewGroup>
          </div>
        </div>
        <div className="second-inner-container">
          <div className="second-inner-left">
            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`)
              }}>
              <Image className="img" src={ProductImage1} />
            </Image.PreviewGroup>
          </div>
          <div className="second-inner-right">
            <p className="second-inner-left-title">Agro Logistic</p>
            <p className="second-inner-left-text">
              In cooperation with Raiffeisen Ware Austria, we have developed Agro Logistics software
              for supporting sales, logistics, and warehousing processes. RWA provided specific
              know-how in a relevant field, while Normasoft utilized years of software development
              experience in a mutual product
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
